import { useState, useEffect } from "react"
import { X } from 'lucide-react'
import { Button } from "./ui/button"
import { Input } from "./ui/input"

interface Tag {
  id: string
  name: string
  color: string
}

export const TagPanel = ({ initialTags = [], updateTags }: any) => {
  const [tags, setTags] = useState<Tag[]>([])
  const [newTagName, setNewTagName] = useState("")
  const [newTagColor, setNewTagColor] = useState("#6366f1")

  useEffect(() => {
    setTags(initialTags)
  }, [initialTags])

  const addTag = () => {
    if (newTagName.trim() !== "") {
      const newTag: Tag = {
        id: Date.now().toString(),
        name: newTagName.trim(),
        color: newTagColor,
      }

      const newTags =[...tags, newTag]
      setTags(newTags)
      updateTags(newTags);
      setNewTagName("")
    }
  }

  const removeTag = (id: string) => {
    const newTags = tags.filter(tag => tag.id !== id);
    setTags(newTags)
    updateTags(newTags);
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      addTag()
    }
  }

  return (
    <div className="p-2">
      <div className="flex flex-wrap gap-2 items-center">
        {tags.map((tag) => (
          <div
            key={tag.id}
            className="group flex items-center px-3 py-1 rounded-full text-sm font-medium transition-all duration-200 ease-in-out hover:ring-2 hover:ring-offset-2 hover:ring-opacity-50"
            style={{
              backgroundColor: tag.color,
              color: getContrastColor(tag.color),
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)'
            }}
          >
            {tag.name}
            <Button
              size="sm"
              variant="ghost"
              className="ml-1 p-0 h-4 w-4 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              onClick={() => removeTag(tag.id)}
            >
              <X className="h-3 w-3" />
              <span className="sr-only">Remove {tag.name} tag</span>
            </Button>
          </div>
        ))}
        <div className="flex items-center space-x-2 bg-gray-100 p-1 rounded-full">
          <Input
            type="text"
            placeholder="Add tag..."
            value={newTagName}
            onChange={(e) => setNewTagName(e.target.value)}
            onKeyPress={handleKeyPress}
            className="w-32 bg-transparent border-none text-sm placeholder-gray-400 focus:outline-none focus:ring-0"
          />
          <Input
            type="color"
            value={newTagColor}
            onChange={(e) => setNewTagColor(e.target.value)}
            className="w-6 h-6 p-0 border-none rounded-full cursor-pointer"
            aria-label="Select tag color"
          />
        </div>
      </div>
      <p className="text-sm text-gray-500">
        Type a tag name and press Enter to add. Click the color picker to change the tag color.
      </p>
    </div>
  )
}

function getContrastColor(hexColor: string = '#ffffff'): string {
  const r = parseInt(hexColor.slice(1, 3), 16)
  const g = parseInt(hexColor.slice(3, 5), 16)
  const b = parseInt(hexColor.slice(5, 7), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? "#000000" : "#ffffff"
}

