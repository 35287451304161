import { useState, useEffect, useCallback, useRef } from 'react'
import { Button } from "../../components/ui/button"
import { ScrollArea } from "../../components/ui/scroll-area"
import { Input } from "../../components/ui/input"
import { Textarea } from "../../components/ui/textarea"
import { TagPanel } from "../../components/tag-panel";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu"
import { PlusCircle, Send, MoreHorizontal, Trash, Edit2, Loader2 } from "lucide-react"
import Navbar from "../../components/navbar"
import ReactMarkdown from 'react-markdown'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select"

import {
    ChatMessage,
    ChatSession,
    getChatSessions,
    getChatMessages,
    addChatSession,
    addChatMessage,
    deleteChatSession,
    updateChatSession,
    Tag
} from '../../apis/chat'

const allTag = {id: '0', name: 'All', color: ''};

function getAllDistinctTags(sessions: ChatSession[]): Tag[] {
    const tagMap = new Map<string, Tag>();

  sessions.forEach(session => {
    (session.tags || []).forEach(tag => {
      if (!tagMap.has(tag.name)) {
        tagMap.set(tag.name, tag);
      }
    });
  });

    return [allTag, ...Array.from(tagMap.values()).sort()];
  }
  
  function getSessionsWithTag(sessions: ChatSession[], selectedTag: Tag): ChatSession[] {
    return sessions.filter(session =>
        (session.tags || []).some(tag => tag.name === selectedTag.name) // Check if any tag matches the given name
      );
  }

export default function Component() {
    const [chatSessions, setChatSessions] = useState<ChatSession[]>([])
    const [currentSession, setCurrentSession] = useState<ChatSession | null>(null)
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const [inputMessage, setInputMessage] = useState('')
    const [editingSessionId, setEditingSessionId] = useState<string | null>(null)
    const [editingTitle, setEditingTitle] = useState('')
    const [editingTags, setEditingTags] = useState<Tag[]>([])
    const [isInitialized, setIsInitialized] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const scrollAreaRef = useRef<HTMLDivElement>(null)
    const messagesEndRef = useRef<HTMLDivElement>(null)

    const [distinctTags, setDistinctTags] = useState<Tag[]>([]);
    const [selectedTag, setSelectedTag] = useState<Tag>(allTag);
    const [filteredSessions, setFilteredSessions] = useState<ChatSession[]>([]);

    useEffect(() => {
        const tags = getAllDistinctTags(chatSessions);
        setDistinctTags(tags);
      }, [distinctTags, chatSessions]);
    
    useEffect(() => {
        if (selectedTag === allTag) {
          setFilteredSessions(chatSessions);
        } else {
          setFilteredSessions(getSessionsWithTag(chatSessions, selectedTag));
        }
      }, [selectedTag, chatSessions]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(scrollToBottom, [messages])

    const fetchChatSessions = useCallback(async () => {
        try {
            const sessions = await getChatSessions()
            setChatSessions(sessions)
            return sessions
        } catch (error) {
            console.error('Failed to fetch chat sessions:', error)
            return []
        }
    }, [])

    const startNewChat = useCallback(async () => {
        try {
            const newSession = await addChatSession()
            setChatSessions(prev => [newSession, ...prev])
            setCurrentSession(newSession)
            setMessages([])
            return newSession
        } catch (error) {
            console.error('Failed to create new chat session:', error)
            return null
        }
    }, [])

    useEffect(() => {
        const initializeChat = async () => {
            if (isInitialized) return

            const sessions = await fetchChatSessions()
            if (sessions.length === 0) {
                await startNewChat()
            } else {
                setCurrentSession(sessions[0])
            }
            setIsInitialized(true)
        }
        initializeChat()
    }, [fetchChatSessions, startNewChat, isInitialized])

    useEffect(() => {
        if (currentSession) {
            fetchChatMessages(currentSession.sessionId)
        }
    }, [currentSession])

    const fetchChatMessages = async (sessionId: string) => {
        try {
            const fetchedMessages = await getChatMessages(sessionId)
            setMessages(fetchedMessages)
        } catch (error) {
            console.error('Failed to fetch chat messages:', error)
        }
    }

    const selectChat = (session: ChatSession) => {
        setCurrentSession(session)
    }

    const deleteChat = async (sessionId: string) => {
        try {
            await deleteChatSession(sessionId)
            setChatSessions(prev => prev.filter(session => session.sessionId !== sessionId))
            if (currentSession?.sessionId === sessionId) {
                const remainingSessions = chatSessions.filter(session => session.sessionId !== sessionId)
                if (remainingSessions.length > 0) {
                    setCurrentSession(remainingSessions[0])
                } else {
                    setCurrentSession(null)
                    setMessages([])
                }
            }
        } catch (error) {
            console.error('Failed to delete chat session:', error)
        }
    }

    const startEditingChat = (sessionId: string, currentTitle: string, tags: Tag[]) => {
        setEditingSessionId(sessionId)
        setEditingTitle(currentTitle)
        setEditingTags(tags)
    }

    const saveEditedChat = async () => {
        if (editingSessionId) {
            try {
                const updatedSession = { sessionId: editingSessionId, title: editingTitle, tags: editingTags }
                await updateChatSession(updatedSession)
                setChatSessions(prev => prev.map(session =>
                    session.sessionId === editingSessionId ? updatedSession : session
                ))
                setEditingSessionId(null)
                setEditingTitle('')
            } catch (error) {
                console.error('Failed to update chat session:', error)
            }
        }
    }

    const sendMessage = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!inputMessage.trim() || !currentSession) return

        try {
            const message: ChatMessage = { id: null, sessionId: currentSession.sessionId, role: 'user', content: inputMessage };
            setMessages(prev => [...prev, message])
            setInputMessage('')
            setIsProcessing(true)


            const response = await addChatMessage(message);
            setMessages(prev => [...prev, response])


        } catch (error) {
            console.error('Failed to send message:', error)
        } finally {
            setIsProcessing(false)
        }
    }

    return (
        <div className="flex flex-col h-screen">
            <Navbar />
            <div className="flex flex-1 overflow-hidden">
                <div className="w-64 border-r border-gray-200 dark:border-gray-800 bg-background overflow-y-auto">
                    <div className="p-4">
                        <Button onClick={startNewChat} className="w-full justify-start" variant="outline">
                            <PlusCircle className="mr-2 h-4 w-4" />
                            New Chat
                        </Button>
                    </div>
                    <div>
                    <div className="p-4">
                    <div className="text-sm font-medium text-muted-foreground">Filter by tag</div>
                    <Select
      value={selectedTag.name}
      onValueChange={(value) => {
        const tag = distinctTags.find(tag => tag.name === value);
        if (tag) {
          setSelectedTag(tag);
        }
      }}
    >
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Select a tag" />
      </SelectTrigger>
      <SelectContent>
        {distinctTags.map((tag) => (
          <SelectItem key={tag.id} value={tag.name}>
            {tag.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
                        </div>   
                    
                    </div>
                    <div className="px-4 py-2 text-sm font-medium text-muted-foreground">Recent Chats</div>
                    <ScrollArea className="h-[calc(100vh-10rem)]">
                        {filteredSessions.map(session => (
                            <div key={session.sessionId} className="group px-2 py-1">
                                <div className="flex items-center rounded-md border border-gray-200 dark:border-gray-700 bg-background transition-colors duration-200">
                                    {editingSessionId === session.sessionId ? (
                                        <form onSubmit={(e) => { e.preventDefault(); saveEditedChat(); }} className="flex-1 px-2">
                                            <Input
                                                value={editingTitle}
                                                onChange={(e) => setEditingTitle(e.target.value)}
                                                onBlur={saveEditedChat}
                                                autoFocus
                                                className="h-8 text-sm"
                                            />
                                        </form>
                                    ) : (
                                        <Button
                                            onClick={() => selectChat(session)}
                                            className={`w-full justify-start font-normal h-8 px-2 ${session.sessionId === currentSession?.sessionId ? 'bg-accent' : ''}`}
                                            variant="ghost"
                                        >
                                            <span className="text-sm truncate">{session.title}</span>
                                        </Button>
                                    )}
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                            <Button
                                                size="icon"
                                                variant="ghost"
                                                className="h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity"
                                            >
                                                <MoreHorizontal className="h-4 w-4" />
                                                <span className="sr-only">More options</span>
                                            </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent align="end">
                                            <DropdownMenuItem onClick={() => startEditingChat(session.sessionId, session.title, session.tags)}>
                                                <Edit2 className="mr-2 h-4 w-4" />
                                                Rename
                                            </DropdownMenuItem>
                                            <DropdownMenuItem onClick={() => deleteChat(session.sessionId)}>
                                                <Trash className="mr-2 h-4 w-4" />
                                                Delete
                                            </DropdownMenuItem>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </div>
                            </div>
                        ))}
                    </ScrollArea>
                </div>
                <div className="flex-1 flex flex-col">
                    <TagPanel initialTags={currentSession?.tags || []} updateTags={ async (tags: Tag[]) => {
                        if(currentSession) {
                            const updatedSession = {...currentSession, tags}
                            await updateChatSession(updatedSession)
                            setCurrentSession(updatedSession)
                            setChatSessions(prev => prev.map(session =>
                                session.sessionId === currentSession.sessionId ? updatedSession : session
                            ))                            
                        }                        
                    }} />
                    <ScrollArea className="flex-1 p-4" ref={scrollAreaRef}>
                        {messages.map((message) => (
                            <div key={message.id} className={`mb-4 ${message.role === 'user' ? 'text-right' : 'text-left'}`}>
                                <div className={`inline-block p-3 rounded-lg ${message.role === 'user'
                                    ? "bg-primary text-primary-foreground rounded-br-none"
                                    : "bg-secondary text-secondary-foreground rounded-bl-none"
                                    }`}>
                                    <ReactMarkdown>{message.content}</ReactMarkdown>
                                </div>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                        {isProcessing && (<Loader2 className="h-4 w-4 ml-2 inline animate-spin" />)}
                    </ScrollArea>
                    <form onSubmit={sendMessage} className="p-4 border-t border-gray-200 dark:border-gray-800">
                        <div className="flex items-center space-x-2">
                            <Textarea
                                value={inputMessage}
                                onChange={(e: any) => setInputMessage(e.target.value)}
                                placeholder="Type your message..."
                                className="flex-1 min-h-[100px]"
                                disabled={isProcessing}
                            />
                            <Button type="submit" size="icon" disabled={isProcessing || !inputMessage.trim()}>
                                <Send className="h-4 w-4" />
                                <span className="sr-only">Send</span>
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}