'use client'

import React, { useEffect, useState } from "react";
import { Loader2 } from 'lucide-react'
import { DocumentWithComments } from '../apis/documents';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs"
import { FileViewer } from "./file-viewer";

type AnalysisResult = {
  'original content': string
  compliance_checks: string[]
  feedbacks: string[]
}

type DocumentPage = {
  page: number
  content: string
  analysis_results: AnalysisResult[]
  source: string[]
}

interface DocumentViewerProps {
  document: DocumentWithComments;
}

export const DocumentViewer: React.FC<DocumentViewerProps> = ({ document }) => {
  const [documentData, setDocumentData] = useState<DocumentPage[]>([])
  const [complianceChecks, setComplianceChecks] = useState<string[]>([])
  const [feedbacks, setFeedbacks] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [activeTab, setActiveTab] = useState("feedback")


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      if (document.responseS3Url) {
        try {
          const response = await fetch(document.responseS3Url)
          if (!response.ok) {
            throw new Error('Failed to fetch document data')
          }
          const { data } = await response.json()

          setDocumentData(data[0].result)

          const allComplianceChecks = data[0].result.flatMap((page: DocumentPage) =>
            page.analysis_results.flatMap(analysisResult => analysisResult.compliance_checks)
          );
        
          const allFeedbacks = data[0].result.flatMap((page: DocumentPage) =>
            page.analysis_results.flatMap(analysisResult => analysisResult.feedbacks)
          );

          setComplianceChecks(allComplianceChecks);
          setFeedbacks(allFeedbacks);

        } catch (err) {
          setError(err instanceof Error ? err.message : 'An unknown error occurred')
        } finally {
          setIsLoading(false)
        }
      }

    }

    fetchData()
  }, [document])

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-red-500">Error: {error}</p>
      </div>
    )
  }

  if (documentData.length === 0) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p>No document data available.</p>
      </div>
    )
  }

  const fileType = document.s3Key.split('.').pop()?.toLowerCase() || 'pdf';

  return (
    <div className="container mx-auto py-6">
      <div className="pr-6">
        <h2 className="text-2xl font-bold mb-4">{document.name}</h2>
        <h3 className="text mb-4">{document.description}</h3>

        <div className="relative flex items-start space-x-5">
      <div className="p-4  w-1/2">
      <FileViewer fileUrl={document.s3Url} fileType={fileType as "pdf" | "docx" | "doc"} />
          </div>

 
        <div
          className="absolute right-0 bg-white border-2 border-gray-300 rounded-lg px-4 py-2 shadow-md w-1/2"
        >
          <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="feedback">Feedback</TabsTrigger>
              <TabsTrigger value="compliance">Compliance Checks</TabsTrigger>
            </TabsList>
            <TabsContent value="feedback">
              <ul className="list-disc pl-5 mt-2 space-y-2">
              {feedbacks.map((check, idx) => (
                    <li key={`feedback-${idx}`} className="text-gray-700">
                      {check}
                    </li>
                  ))}
              </ul>
            </TabsContent>
            <TabsContent value="compliance">
              <ul className="list-disc pl-5 mt-2 space-y-2">
                {complianceChecks.map((check, idx) => (
                    <li key={`compliance-${idx}`} className="text-gray-700">
                      {check}
                    </li>
                  ))}
              </ul>
            </TabsContent>
          </Tabs>
        </div>
      
    </div>
        
      </div>
    </div>
  )
}