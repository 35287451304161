import { useState, useEffect } from "react";
import { Button } from "../../components/ui/button"
import { PageLayout } from "../../components/page-layout"
import { RecentTagsWidget } from "../../components/recent-tags-widget"
import { FileText, FilePlus, MessageSquare } from "lucide-react"
import { fetchUserAttributes } from "aws-amplify/auth";
import { Documents } from './documents';


import { Link } from 'react-router-dom';

export default function Dashboard() {
    const [name, setName] = useState('');

    useEffect(() => {
        getUserDetails();
    }, []);

    const getUserDetails = async () => {
        try {
            const { given_name = '' } = await fetchUserAttributes();
            setName(given_name)
        } catch {
            //todo
        }
    };


    return (
        <PageLayout>
            <div className="container mx-auto p-4">
                <h1 className="text-4xl font-bold mb-8">Welcome {name}</h1>

                <div className="w-full max-w-6xl mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                            <Link to='/chat'>
                                <Button
                                    variant="outline"
                                    className="w-full h-24 border-2 hover:bg-black hover:text-white transition-colors"
                                >
                                    <MessageSquare className="mr-2 h-6 w-6" />
                                    Chat with Lumi
                                </Button>
                            </Link>
                        </div>
                        <div>
                            <Link to='/documents'>
                                <Button
                                    variant="outline"
                                    className="w-full h-24 border-2 hover:bg-black hover:text-white transition-colors"
                                >
                                    <FileText className="mr-2 h-6 w-6" />
                                    Manage Documents
                                </Button>
                            </Link>
                        </div>
                        <div>
                            <Link to='/documents/new'>
                                <Button
                                    variant="outline"
                                    className="w-full h-24 border-2 hover:bg-black hover:text-white transition-colors"
                                >
                                    <FilePlus className="mr-2 h-6 w-6" />
                                    Add Document
                                </Button>
                            </Link>
                        </div>
                    </div>

                </div>

                <RecentTagsWidget />

                <Documents />


            </div >
        </PageLayout>
    )
}
