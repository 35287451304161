'use client'

import * as React from "react"
import { Link, useLocation } from 'react-router-dom';
import { MessageSquare, FileText, FilePlus, Sun, LogOut } from 'lucide-react'
import { signOut } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";

import { Button } from "./ui/button"
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet"
import { cn } from "./lib/utils"



export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const navItems = [
    { name: "Chat", icon: MessageSquare, href: "/chat" },
    { name: "Documents", icon: FileText, href: "/documents" },
    { name: "Upload document", icon: FilePlus, href: "/documents/new" },
  ]

  const logOut = async () => {
    try {
      await signOut();
      navigate("/login", {
        replace: true,
      });
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <nav className="border-b">
      <div className="flex h-16 items-center px-4 md:px-6">
        <Link to="/" className="flex items-center space-x-2">
          <Sun className="h-6 w-6" />
          <span className="font-bold">Lumineux</span>
        </Link>
        <div className="ml-auto flex items-center space-x-4">
          <div className="hidden md:flex md:items-center md:space-x-4">
            {navItems.map((item) => {
              const isActive = pathname === item.href
              return (
                <Button
                  key={item.name}
                  variant={isActive ? "secondary" : "ghost"}
                  asChild
                  disabled={isActive}
                  aria-current={isActive ? "page" : undefined}
                >
                  <Link to={item.href}>
                    <item.icon className="mr-2 h-4 w-4" />
                    {item.name}
                  </Link>
                </Button>
              )
            })}
            <Button variant="outline" onClick={logOut}>
              <LogOut className="mr-2 h-4 w-4" />
              Logout
            </Button>
          </div>
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="outline" size="icon" className="md:hidden">
                <Sun className="h-4 w-4" />
                <span className="sr-only">Toggle menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="right">
              <SheetHeader>
                <SheetTitle>Menu</SheetTitle>
              </SheetHeader>
              <div className="mt-4 flex flex-col space-y-2">
                {navItems.map((item) => {
                  const isActive = pathname === item.href
                  return (
                    <Button
                      key={item.name}
                      variant={isActive ? "secondary" : "ghost"}
                      asChild
                      disabled={isActive}
                      className={cn(
                        "justify-start",
                        isActive && "bg-muted font-semibold"
                      )}
                      aria-current={isActive ? "page" : undefined}
                    >
                      <Link to={item.href}>
                        <item.icon className="mr-2 h-4 w-4" />
                        {item.name}
                      </Link>
                    </Button>
                  )
                })}
                <Button variant="outline" className="justify-start" onClick={logOut}>
                  <LogOut className="mr-2 h-4 w-4" />
                  Logout
                </Button>
              </div>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </nav>
  )
}