import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Badge } from "./ui/badge"
import { Button } from "./ui/button"
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "./ui/card"
import { ScrollArea } from "./ui/scroll-area"
import { Skeleton } from "./ui/skeleton"
import { fetchAllDocuments, fetchAllChatSessions, Tag, Document, ChatSession } from '../lib/api'

function getUnionOfTags(documents: Document[], chatSessions: ChatSession[]): Tag[] {
  const tagSet = new Set<string>()
  const tagMap = new Map<string, Tag>()

    ;[...documents, ...chatSessions].forEach(item => {
      (item.tags || []).forEach(tag => {
        tagSet.add(tag.id)
        tagMap.set(tag.id, tag)
      })
    })

  return Array.from(tagSet).map(id => tagMap.get(id)!)
}

function filterItemsByTag<T extends { tags: Tag[] }>(items: T[], selectedTag: Tag | null): T[] {
  if (!selectedTag) return items
  return items.filter(({tags = []}) => tags.some(tag => tag.id === selectedTag.id))
}

export function RecentTagsWidget() {
  const [tags, setTags] = useState<Tag[]>([])
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null)
  const [documents, setDocuments] = useState<Document[]>([])
  const [chatSessions, setChatSessions] = useState<ChatSession[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)
      try {
        const [fetchedDocuments, fetchedChatSessions] = await Promise.all([
          fetchAllDocuments(),
          fetchAllChatSessions()
        ])
        setDocuments(fetchedDocuments)
        setChatSessions(fetchedChatSessions)
        setTags(getUnionOfTags(fetchedDocuments, fetchedChatSessions))
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setIsLoading(false)
      }
    }
    loadData()
  }, [])

  const filteredDocuments = filterItemsByTag(documents, selectedTag)
  const filteredChatSessions = filterItemsByTag(chatSessions, selectedTag)

  return (
    <Card className="w-full max-w-6xl mx-auto mt-8">
      <CardHeader>
        <CardTitle className="text-xl font-normal">Recently used tags</CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="w-full whitespace-nowrap">
          <div className="flex space-x-2 pb-4">
            {isLoading ? (
              Array(5).fill(0).map((_, i) => (
                <Skeleton key={i} className="h-6 w-16 rounded-full" />
              ))
            ) : (
              tags.map((tag) => (
                <Badge
                  key={tag.id}
                  variant={selectedTag?.id === tag.id ? "default" : "secondary"}
                  className="cursor-pointer font-normal"
                  onClick={() => setSelectedTag(selectedTag?.id === tag.id ? null : tag)}
                >
                  {tag.name}
                </Badge>
              ))
            )}
          </div>
        </ScrollArea>
        <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
          {(isLoading || filteredDocuments.length > 0) && (
            <Card>
              <CardHeader>
                <CardTitle className="text-lg font-normal">Documents</CardTitle>
              </CardHeader>
              <CardContent>
                {isLoading ? (
                  Array(3).fill(0).map((_, i) => (
                    <Skeleton key={i} className="h-6 w-full mb-2" />
                  ))
                ) : filteredDocuments.length > 0 ? (
                  <ul className="space-y-2">
                    {filteredDocuments.slice(0, 5).map((doc) => (
                      <li key={doc.id}>
                        <Link
                          to={doc.url}
                          className="text-black hover:underline font-normal flex items-center"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                          </svg>
                          {doc.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </CardContent>
              <CardFooter>
                <Button variant="link" asChild className="p-0">
                  <Link to="/documents">
                    View all documents
                  </Link>
                </Button>
              </CardFooter>
            </Card>
          )}
          {(isLoading || filteredChatSessions.length > 0) && (
            <Card>
              <CardHeader>
                <CardTitle className="text-lg font-normal">Chat Sessions</CardTitle>
              </CardHeader>
              <CardContent>
                {isLoading ? (
                  Array(3).fill(0).map((_, i) => (
                    <Skeleton key={i} className="h-6 w-full mb-2" />
                  ))
                ) : filteredChatSessions.length > 0 ? (
                  <ul className="space-y-2">
                    {filteredChatSessions.slice(0, 5).map((session) => (
                      <li key={session.id}>
                        <Link
                          to={session.url}
                          className="text-black hover:underline font-normal flex items-center"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                          </svg>
                          {session.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </CardContent>
              <CardFooter>
                <Button variant="link" asChild className="p-0">
                  <Link to="/chat">
                    View all chat sessions
                  </Link>
                </Button>
              </CardFooter>
            </Card>
          )}
        </div>
        {!isLoading && filteredDocuments.length === 0 && filteredChatSessions.length === 0 && (
          <p className="text-center text-muted-foreground mt-4">
            No documents or chat sessions found for the selected tag.
          </p>
        )}
      </CardContent>
    </Card>
  )
}

