export interface Tag {
  id: string
  name: string
}

export interface Document {
  id: string
  title: string
  url: string
  tags: Tag[]
}

export interface ChatSession {
  id: string
  title: string
  url: string
  tags: Tag[]
}

const mockDocuments: Document[] = [
  { id: '1', title: 'Q2 Report', url: '/documents/q2-report', tags: [{ id: '1', name: 'Work' }, { id: '3', name: 'Project A' }] },
  { id: '2', title: 'Project Proposal', url: '/documents/project-proposal', tags: [{ id: '1', name: 'Work' }, { id: '3', name: 'Project A' }] },
  { id: '3', title: 'Meeting Notes', url: '/documents/meeting-notes', tags: [{ id: '1', name: 'Work' }, { id: '4', name: 'Research' }] },
  { id: '4', title: 'Research Paper', url: '/documents/research-paper', tags: [{ id: '4', name: 'Research' }, { id: '5', name: 'Ideas' }] },
  { id: '5', title: 'Personal Journal', url: '/documents/personal-journal', tags: [{ id: '2', name: 'Personal' }] },
]

const mockChatSessions: ChatSession[] = [
  { id: '1', title: 'Team Standup', url: '/chats/team-standup', tags: [{ id: '1', name: 'Work' }, { id: '3', name: 'Project A' }] },
  { id: '2', title: 'Client Meeting', url: '/chats/client-meeting', tags: [{ id: '1', name: 'Work' }, { id: '3', name: 'Project A' }] },
  { id: '3', title: 'Brainstorming Session', url: '/chats/brainstorming', tags: [{ id: '5', name: 'Ideas' }] },
  { id: '4', title: 'Code Review', url: '/chats/code-review', tags: [{ id: '1', name: 'Work' }, { id: '3', name: 'Project A' }] },
  { id: '5', title: 'Personal Chat', url: '/chats/personal-chat', tags: [{ id: '2', name: 'Personal' }] },
]

export async function fetchAllDocuments(): Promise<Document[]> {
  await new Promise(resolve => setTimeout(resolve, 1000))
  return mockDocuments
}

export async function fetchAllChatSessions(): Promise<ChatSession[]> {
  await new Promise(resolve => setTimeout(resolve, 1000))
  return mockChatSessions
}

