import { useState, useEffect, useCallback } from 'react'
import { PageLayout } from "../../../components/page-layout"
import { useParams } from 'react-router-dom';
import { DocumentWithComments, getDocument } from '../../../apis/documents';
import { DocumentViewer } from '../../../components/document-viewer';

export default function Review() {
    const { id } = useParams();
    const [selectedDocument, setDocument] = useState<DocumentWithComments | null>(null)

    const fetchDocument = useCallback(async () => {
        try {
            const document = await getDocument(id as string);
            setDocument(document)
        } catch (error) {
            console.error('Failed to fetch document:', error)
            return []
        }
    }, [id])

    useEffect(() => {
        fetchDocument()
    }, [fetchDocument])

    return (
        <PageLayout>
            {selectedDocument?.responseS3Url && <DocumentViewer document={selectedDocument} />}
        </PageLayout>
    )
}