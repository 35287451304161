import { getHttpClient } from './index';

export type Document = {
    documentId?: string
    name: string
    description: string
    tags: string[]
    status: 'PENDING' | 'DONE'
}

export type DocumentWithComments = Document & {
    s3Key: string
    responseS3Url?: string
    s3Url: string
}

export type Section = {
    id: string
    title: string
    content: {
        text: string
        type: 'heading' | 'bullet' | 'text'
    }[]
}

export type Comment = {
    id: string
    sectionId: string
    author: {
        name: 'Lumi'
    }
    content: string
    timestamp: string
}

export const getDocuments = async (): Promise<Document[]> => {
    return (await getHttpClient().get('documents')).data.items
}

export const getDocument = async (id: string): Promise<DocumentWithComments> => {
    return (await getHttpClient().get(`documents/${id}`)).data
}

export const addDocument = async (file: File, document: Document): Promise<void> => {
    try {
        const signedUrl = await getSignedUrl(file.name, file.type);
        const response = await fetch(signedUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': file.type,
            },
            body: file,
        });
        if (!response.ok) {
            throw new Error('Failed to upload file to S3');
        }
        //TODO process document api to store document json in dynamo and trigger contract review process
        await processDocument(file.name, document)
    } catch (error) {
        throw error
    }
}

const processDocument = async (fileName: string, document: Document) => {
    try {
        const { data } = await getHttpClient().post('documents', {
            name: document.name, description: document.description, tags: document.tags, s3Key: fileName
        })
        return data as Document
    } catch (error) {
        console.error('Error processing document:', error)
    }
}

const getSignedUrl = async (fileName: string, fileType: string): Promise<string> => {
    try {
        const { data } = await getHttpClient().post('generate-presigned-url', JSON.stringify({ fileName, fileType }));
        return data.url;
    } catch (error) {
        console.error("Error fetching signed URL:", error);
        throw error;
    }
}
