import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "@react-pdf-viewer/core/lib/styles/index.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface FileViewerProps {
    fileUrl: string;
    fileType: "pdf" | "docx" | "doc";
}

export const FileViewer: React.FC<FileViewerProps> = ({ fileUrl, fileType }) => {
    const [fileContent, setFileContent] = useState<string | null>(null);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchFile = async () => {
            try {
                const response = await fetch(fileUrl);
                if (!response.ok) {
                    throw new Error(`Failed to fetch file. Status: ${response.status}`);
                }
                const blob = await response.blob();

                if (fileType === "docx" || fileType === "doc") {
                    const reader = new FileReader();
                    reader.onload = async (e) => {
                        if (e.target?.result) {
                            const mammoth = await import("mammoth");
                            const { value } = await mammoth.convertToHtml({
                                arrayBuffer: e.target.result as ArrayBuffer,
                            });
                            setFileContent(value);
                        }
                    };
                    reader.readAsArrayBuffer(blob);
                } else if (fileType === "pdf") {
                    setFileContent(URL.createObjectURL(blob));
                } else {
                    throw new Error("Unsupported file type");
                }
            } catch (err: any) {
                setError(err.message || "An error occurred while loading the file.");
            }
        };

        fetchFile();
    }, [fileUrl, fileType]);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };

    if (error) {
        return <div style={{ color: "red" }}>Error: {error}</div>;
    }

    if (fileType === "pdf") {
        return fileContent ? (
            <div>
                <Document file={fileContent} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                    ))}
                </Document>
            </div>
        ) : (
            <div>Loading PDF...</div>
        );
    } else if (fileType === "docx" || fileType === "doc") {
        return fileContent ? (
            <div
                dangerouslySetInnerHTML={{ __html: fileContent }}
                style={{ padding: "10px", background: "#f9f9f9" }}
            />
        ) : (
            <div>Loading Document...</div>
        );
    } else {
        return <div>Unsupported file type</div>;
    }
};
